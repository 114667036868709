<div #rootContainer [attr.data-test-id]="'table__' + tableName">
  @if (tableSettings?.heading && settings.includes(tableSettingsEnum.SHOWHEADER)) {
    <naris-toolbar class="naris-table-toolbar">
      <naris-toolbar-item class="naris-table-toolbar-title">
        @if (!widgetConfig || !editMode || !titleEdit) {
          <h3 class="mr-8">{{tableTitle}}</h3>
        } @else {
          <input
            [(ngModel)]="titleInput"
            type="text"
            [value]="tableTitle"
            [ngStyle]="{'width': (titleInput.length) + 'ch'}"
            [placeholder]="'title' | translate"
            maxlength="50"
            (keypress)="onTitleEdit($event)"
            (blur)="saveTitle()"
            [matTooltip]="'table.chars_left' | translate:{count: 50 - titleInput.length}"
            matTooltipPosition="above"
          >
        }
        @if (!!widgetConfig && editMode) {
          <naris-button
            icon="edit"
            color="white"
            size="medium"
            [matTooltip]="(titleEdit ? 'table.set_title' : 'table.edit_title') | translate"
            matTooltipPosition="right"
            (clicked)="onTitleEdit()"
            [active]="titleEdit"
            id="TableSetEditTitle"
          />
        }
      </naris-toolbar-item>
      <naris-toolbar-item 
        class="ml-auto" 
        [style.position]="tableSettings?.child && 'absolute'"      
        [style.right.rem]="tableSettings?.child && '1'"
      >
        @if (tableUpdateAssignmentsAction) {
          <naris-button
            color="white"
            class="naris-table-toolbar-action"
            icon="manage_accounts"
            (clicked)="onClickAction(tableUpdateAssignmentsAction)"
            [matTooltip]="tableUpdateAssignmentsAction.label || ''"
            id="TableUpdateAssignmentsAction"
          />
        }
        @for (tableAction of tableActions; track $index) {
          @if (!tableAction.type?.includes('create')) {
            <naris-button
              color="primary"
              class="naris-table-toolbar-action"
              [disabled]="!tableAction.href"
              (clicked)="onClickAction(tableAction)"
              [id]="'Table' + tableAction.name"
            >
              {{tableAction.label}}
            </naris-button>
          }
          
        }
        @if (tableSettings?.check && isModal) {
          <naris-button
            color="primary"
            class="naris-table-toolbar-action"
            size="small"
            (clicked)="submitSelection.emit(selection.selected)"
            id="TableSubmitSelection"
          >
            {{'table.submit_selection' | translate}}
          </naris-button>
          <naris-button
            color="danger"
            size="small"
            class="naris-table-toolbar-action"
            [disabled]="!selection.selected.length"
            (clicked)="selection.clear()"
            id="TableClearSelection"
          >
            {{'table.clear_selection' | translate}}
          </naris-button>
        }
        @if (!!tableMarkAllAsRead) {
          <naris-button
            color="white"
            icon="mark-as-read"
            [matTooltip]="'table.mark_all_as_read' | translate"
            matTooltipPosition="above"        
            (clicked)="onClickAction(tableMarkAllAsRead)"
            id="TableMarkAllAsRead"
          />
        }
        @if (showSaveFilters) {
          <naris-button
            class="mr-1"
            icon="save_floppy"
            color="accent"
            matTooltipPosition="above"
            matTooltipShowDelay="200"
            [matTooltip]="'table.save_filters' | translate"
            (clicked)="saveConfiguration()"
            id="TableSaveFilters"
          />
        }
        @if (tableSettings?.options?.includes('filter') && !!dataSource.filterMeta.length) {
          <naris-button
            color="white"
            icon="filter"
            [matTooltip]="'filter' | translate"
            matTooltipPosition="above"
            [active]="activeOption === 'filter'"
            (onMenuOpen)="activeOption = 'filter'; dataSource.optionsChanged = true;"
            (onMenuClose)="activeOption = null"
            [matMenuTriggerFor]="filterMenu"
            id="TableFilter"
          />
        }
        @if (tableSettings?.options?.includes('arrange') && (rootColumns?.length ?? -1) > 1) {
          <naris-button
            color="white"
            icon="preferences"
            [matTooltip]="'table.arrange_columns' | translate"
            matTooltipPosition="above"
            [active]="activeOption === 'arrange'"
            (onMenuOpen)="activeOption = 'arrange'; dataSource.optionsChanged = true;"
            (onMenuClose)="activeOption = null"
            [matMenuTriggerFor]="columnMenu"
            id="TableArrangeColumns"
          />
        }
        <mat-menu #filterMenu="matMenu" xPosition="before" class="naris-table-menu">
          <div class="naris-table-filter" (click)="$event.stopPropagation(); filterFormClick($event)" tabindex="0">
            <form [formGroup]="dataSource.filterForm">
              @for (input of dataSource.filterMeta; track $index) {
                <app-form-input
                  [input]="input"
                  [formGroup]="dataSource.filterForm"
                  (enterPressed)="dataSource.handleFilter(dataSource.filterForm.get(input.id)?.value, input)"
                  [isFilter]="true"
                  [formId]="tableName + 'Filter'"
                />
              }
            </form>
          </div>
        </mat-menu>
        <mat-menu #columnMenu="matMenu" xPosition="before" class="naris-table-menu">
          <div class="naris-drop-list" cdkDropList (cdkDropListDropped)="drop($event)">
            @for (column of rootColumns; track $index) {
              <div
                (click)="$event.stopPropagation()"
                class="naris-drag-item"
                tabindex="0"
                [ngClass]="{active: columnsToDisplay.includes(column.id.toString())}"
                cdkDrag
                [cdkDragDisabled]="!columnsToDisplay.includes(column.id.toString())"
              >
                <div class="naris-drag-placeholder" *cdkDragPlaceholder></div>
                <naris-icon icon="drag" cdkDragHandle class="naris-drag-icon" />
                <naris-slidetoggle
                  [disabled]="activeColumns.length <= 1 && !!activeColumns[0] && activeColumns[0].id === column.id"
                  class="slide-toggle"
                  [checked]="column.active"
                  (toggled)="toggleColumn(column)"
                  [id]="'TableToggleColumn'"
                >
                  {{column.label}}
                </naris-slidetoggle>
              </div>
            }
            
        </div>
        </mat-menu>
      </naris-toolbar-item>
    </naris-toolbar>
  }
  
  @if (tableSettings?.heading && tableSettings?.options?.includes('filter')) {
    <naris-toolbar [@filterExpand]="!!dataSource.activeFilters.length ? 'expanded' : 'collapsed'" class="naris-table-toolbar">
      <naris-toolbar-item>
        <naris-icon icon="filter" class="naris-table-filter-icon" />
        <span class="naris-table-filter-span">{{'table.filtered_on' | translate}} </span>
      </naris-toolbar-item>
      <naris-toolbar-item>
        <mat-chip-listbox #chipGrid>
          @for (chip of (dataSource.filterChips$ | async); track $index) {
            <mat-chip-option
              [removable]="true"
              [selectable]="false"
              (removed)="dataSource.optionsChanged = true; dataSource.removeChip(chip);"
            >
              {{ chip.label }}{{ chip.separator }} {{ chip.value }}
              <naris-icon matChipRemove icon="cancel" />
            </mat-chip-option>
          }
        </mat-chip-listbox>
      </naris-toolbar-item>
    </naris-toolbar>
  }
  
  <div 
    [ngClass]="{'modal-wrapper': isModal}" 
    [style.height]="error ? '0px' : 'auto'"
    #modalWrapper 
    [style.position]="!tableSettings?.child && 'relative'"
    [style.width.px]="tableSettings?.child ? rootWidth - (childTableIndents * 33) : null"
  >
    @if (!tableLayouthint?.includes('list-summary')) {
      <div
        class="naris-table-wrapper"
        [ngStyle]="{'height.px': !!widgetPanelSize?.height ? widgetPanelSize!.height! - 96 : null}"
      >
        <table
          mat-table
          matSort
          matSortDisabled
          class="naris-table-element"
          [dataSource]="dataSource"
          [trackBy]="trackByID"
          multiTemplateDataRows
        >
          @for (column of rootColumns; track $index) {
            <ng-container
              [matColumnDef]="column.id.toString()"
              [sticky]="stickyColumns.includes(column.id.toString())"
              [stickyEnd]="stickyColumns.includes(column.id.toString())"
            >
              <th
                mat-header-cell
                mat-sort-header
                *matHeaderCellDef
                class="naris-table-dynamic-column"
                [ngClass]="{sticky: stickyColumns.includes(column.id.toString())}"
              >
                @if (tableSettings?.check && columnsToDisplay.indexOf(column.id.toString()) === 0) {
                  <naris-checkbox
                    class="naris-table-checkbox"
                    (click)="$event.stopPropagation()"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="isIndeterminate()"
                    (changed)="$event ? masterToggle() : null"
                    [id]="'TableColumnsToDisplay' + column.id"
                    
                  />
                }
                @if ((tableSettings?.parentTable || tableSettings?.nestedTable) && columnsToDisplay.indexOf(column.id.toString()) === 0) {
                  <div class="naris-table-no-child"></div>
                }
                <span
                  (click)="sortableColumns.includes(column.id.toString()) && tableSettings?.sorting && !refreshing ? dataSource.sortColumn(column) : null"
                  tabindex="0"
                  class="naris-table-dynamic-column-header"
                  [ngClass]="{sortable: sortableColumns.includes(column.id.toString()) && tableSettings?.sorting}"
                >
                  {{column.label}}
                  @if (sortableColumns.includes(column.id.toString()) && tableSettings?.sorting && settings.includes(tableSettingsEnum.SHOWSORTING)) {
                    <naris-icon
                      class="sort-icon"
                      [ngClass]="{
                        desc: dataSource.sortingMeta.sortDirection !== 'asc' && dataSource.sortingMeta.sortedColumn === column.id,
                        active: dataSource.sortingMeta.sortedColumn === column.id
                      }"
                      icon="keyboard_arrow_down"
                    />
                  }
                </span>
                @if (!tableSettings?.child && settings.includes(tableSettingsEnum.SHOWPIN)) {
                  <naris-button
                    size="small"
                    icon="pin"
                    color="transparent"
                    class="naris-table-pin"
                    [active]="stickyColumns.includes(column.id.toString())"
                    [matTooltip]="(stickyColumns.includes(column.id.toString()) ? 'table.detach' : 'table.attach') | translate"
                    matTooltipPosition="above"
                    matTooltipShowDelay="200"
                    (click)="$event.stopPropagation(); stick(column.id.toString());"
                    id="TableAttachDetachNotChild"
                  />
                }
              </th>
              <td
                mat-cell
                *matCellDef="let row;"
                [ngClass]="{
                  sticky: stickyColumns.includes(column.id.toString()), 
                  blocked: isBlocked(row), 
                  'full-text': column.layouthint?.includes('full-text'), 
                  'not-read': !row.NotificationRead && isMessagesTable, 
                  'read': !!row.NotificationRead && isMessagesTable,
                  'disabled': (router.url.includes('archive') && (row.Archive !== null && row.Archive !== undefined) && !row.Archive) || (!router.url.includes('archive') && !!row.Archive)
                }"
                [style.background-color]="column.layouthint?.includes('color-value') ? parseColorValue(row[column.id]).color  : 'inherit'"
                [matTooltip]="(router.url.includes('archive') && (row.Archive !== null && row.Archive !== undefined) && !row.Archive ? 'not-archived' : !router.url.includes('archive') && !!row.Archive ? 'archived' : '') | translate"
              >
                <div class="naris-table-td">
                  @if (columnsToDisplay.indexOf(column.id.toString()) === 0) {
                    @if ((tableSettings?.parentTable || tableSettings?.nestedTable) && !!row.HasChildren) {
                      @if (loadingChildren.includes(row)) {
                        <naris-icon icon="loader" class="naris-table-td-loader" />
                      } @else {
                        <naris-icon
                          icon="navigate_next"
                          class="naris-table-expand"
                          [ngClass]="{expanded: expandedChildren.includes(row)}"
                          (click)="$event.stopPropagation(); toggleChild(row)"
                        />
                      }
                    }
                    @if ((tableSettings?.parentTable || tableSettings?.nestedTable) && !row.HasChildren) {
                      <div class="naris-table-no-child"></div>
                    }
                    @if (tableSettings?.check && columnsToDisplay.indexOf(column.id.toString()) === 0) {
                      <naris-checkbox
                        class="naris-table-checkbox"
                        (click)="$event.stopPropagation()"
                        (changed)="$event ? toggleRow(row) : null"
                        [checked]="isChecked(row)"
                        [id]="'TableColumnsToDisplay' + column.id"
                      />
                    }
                  }
                  <ng-container [formGroup]="rowForm">
                    @if (tableSettings?.inlineEdit && editRow === row && editables.includes(column.id.toString())) {
                      <input
                        type="text"
                        [value]="row[column.id]"
                        [formControlName]="column.id"
                      >
                    } @else {
                      @if (isStateColumn(column.id.toString())) {
                        <span class="naris-table-indicator" [ngClass]="getStateColor(row, column) || ''"></span>
                      }
                      @if (column.layouthint?.includes('color') && !column.layouthint?.includes('rich_text') && !!row[column.id]) {
                        <span
                          class="color-box"
                          [ngClass]="{prio: row[column.id].includes('|\\|')}"
                          [ngStyle]="{backgroundColor: row[column.id].includes('|\\|') ? row[column.id].split('|\\|')[0] : row[column.id]}"
                          [matTooltip]="row[column.id].includes('|\\|') ? row[column.id].split('|\\|')[0] : row[column.id]"
                          matTooltipPosition="right"
                        ></span>
                      }
                      @if (![true, false].includes(row[column.id])) {
                        <span class="data-cell">
                          @if (!!column.layouthint?.includes('color') && !column.layouthint?.includes('rich_text') && row[column.id]?.includes('|\\|')) {
                            <span>
                              {{mapCellVal(row[column.id].split('|\\|')[1])}}
                            </span>
                          }
                          @if (!column.layouthint?.includes('color') && !column.layouthint?.includes('rich_text')) {
                            @if (!column.layouthint?.includes('full-text')) {
                              @if (!column.layouthint?.includes('chips') && !column.layouthint?.includes('color-value')) {
                                <span
                                  [ngStyle]="{'cursor': showTooltip(row[column.id]) && 'help'}"
                                  [matTooltip]="showTooltip(row[column.id]) ? mapCellVal(row[column.id], false) : ''"
                                  [matTooltipClass]="{truncated: showTooltip(row[column.id])}"
                                  matTooltipPosition="above"
                                >
                                  {{ mapCellVal(row[column.id], true, row, column) || '-' }}
                                </span>
                              } @else if (column.layouthint?.includes('color-value')) {
                                <span [ngClass]="{'light-text': textColor(parseColorValue(row[column.id]).color) === 'light'}">
                                  {{ parseColorValue(row[column.id]).value }}
                                </span>
                              } @else {
                                <span
                                  class="tag-column-data"
                                  [ngStyle]="{'cursor': hasOverflow(tagSpan) && 'help'}"
                                  [matTooltip]="hasOverflow(tagSpan) ? mapCellVal(row[column.id], false, row, column) : ''"
                                  [matTooltipClass]="{truncated: hasOverflow(tagSpan)}"
                                  matTooltipPosition="above"
                                  #tagSpan
                                >
                                  {{ mapCellVal(row[column.id], true, row, column) }}
                                </span>
                              }
                            } @else {
                              <span>{{ mapFullTextVal(row[column.id]) || '-' }}</span>
                            }
                          }
                          @if ((isArray(row[column.id]) && row[column.id].length > 3) && !column.layouthint?.includes('rich_text')) {
                            <a
                              class="data-cell-all"
                              tabindex="0"
                              [matMenuTriggerFor]="rowMenu"
                              (click)="$event.stopPropagation()"
                            >
                              {{'table.show_all' | translate}} {{row[column.id].length}}
                            </a>
                          }
                          @if (column.layouthint?.includes('rich_text')) {
                            <div class="rich-text-container">
                              <naris-icon
                                icon="navigate_next"
                                class="naris-table-expand"
                                [ngClass]="{expanded: hasRichTextChild(row)}"
                                (click)="$event.stopPropagation(); toggleRichText(row, column.id)"
                              />
                              <span class="rich-text-label">
                                {{row[column.id] | richText}}
                              </span>
                            </div>
                          }
                        </span>
                      } @else {
                        <naris-icon
                          [icon]="row[column.id] ? 'check' : 'close'"
                          [iconClass]="row[column.id] ? 'green' : 'warn'"
                          class="boolean-icon"
                        />
                      }
                      @if (!!row.IsPrivate && columnsToDisplay.indexOf(column.id.toString()) === 0) {
                        <span>
                          <naris-icon
                            [matTooltip]="'table.object_is_private' | translate"
                            matTooltipPosition="right"
                            class="private"
                            icon="visibility_off"
                          />
                        </span>
                      }
                      @if (column.title && (!!getDesc(row) || !!getQuestions(row))) {
                        <span
                          cdkOverlayOrigin
                          (mouseenter)="row['showDescriptionOverlay' + column.id] = true"
                          (mouseleave)="row['showDescriptionOverlay' + column.id] = false"
                          #showDescription="cdkOverlayOrigin"
                        >
                          <naris-icon
                            class="desc-icon"
                            icon="info-outline"
                          />
                        </span>
                        <ng-template
                          cdkConnectedOverlay
                          [cdkConnectedOverlayOrigin]="showDescription"
                          [cdkConnectedOverlayOpen]="row['showDescriptionOverlay' + column.id]"
                        >
                          <div class="case-view-header__info-overlay">
                            <span class="case-view-header__info-overlay__attribute">
                              <span class="case-view-header__info-overlay__label">{{('table.description' | translate) }}</span>
                              <span>{{getDesc(row)}}</span>
                            </span>
                            @if (!!getQuestions(row)) {
                              <span class="case-view-header__info-overlay__attribute">
                                <span class="case-view-header__info-overlay__label">{{('table.questions' | translate)}}</span>
                                <span>{{getQuestions(row)}}</span>
                              </span>
                            }
                          </div>
                        </ng-template>
                      }
                      @if (column.title && !!getRelatedRecordType(row)?.length) {
                        <span class="pl-2">
                          @for (icon of getRelatedRecordType(row); track $index) {
                            <naris-icon
                              class="desc-icon available-icon"
                              [matTooltip]="getIconLabel(icon)"
                              matTooltipPosition="above"
                              matTooltipClass="desc-icon-tooltip"
                              [icon]="getIconName(icon)"                    
                              (click)="$event.stopPropagation(); clickAvailable(row, icon);"
                            />
                          }
                        </span>
                      }
                    }
                  </ng-container>
                  <mat-menu
                    class="naris-table-menu--row"
                    [ngClass]="{text: typeOf(row[column.id]) === 'string'}"
                    #rowMenu="matMenu"
                    xPosition="after"
                    yPosition="above"
                  >
                    @if (isArray(row[column.id])) {
                      <table>
                        @for (row of row[column.id]; track $index) {
                          <tr>
                            @if (isArray(row)) {
                              @for (item of row; track $index) {
                                <td>{{item}}</td>
                              }
                            } @else {
                              <td>{{row}}</td>
                            }
                          </tr>
                        }
                      </table>
                    }
                    @if (typeOf(row[column.id]) === 'string') {
                      <p>{{row[column.id]}}</p>
                    }
                  </mat-menu>
                </div>
              </td>
            </ng-container>
          }
          @if (hasRowActions() && !tableSettings?.child) {
            <ng-container
              matColumnDef="actions"
              [sticky]="stickyColumns.includes('actions')"
              [stickyEnd]="stickyColumns.includes('actions')"
            >
              <th
                #actionColumnHeader
                mat-header-cell
                *matHeaderCellDef
                class="naris-table-actions-column"
                [ngClass]="{sticky: stickyColumns.includes('actions')}"
                [style.visibility]="hasRowActions() && !tableSettings?.parentTable && tableSettings?.child && stickyColumns.includes('actions') && 'hidden'"
              >
                <div class="naris-table-th">
                  {{'table.actions' | translate}}
                  <naris-button
                    size="small"
                    icon="pin"
                    color="transparent"
                    class="naris-table-pin"
                    [active]="stickyColumns.includes('actions')"
                    [matTooltip]="(stickyColumns.includes('actions') ? 'table.detach' : 'table.attach') | translate"
                    matTooltipPosition="above"
                    matTooltipShowDelay="200"
                    (click)="$event.stopPropagation(); stick('actions');"
                    id="TableAttachDetach"
                  />
                </div>
              </th>
              <td
                mat-cell
                *matCellDef="let row;"
                class="naris-table-actions-column"
                [ngClass]="{sticky: stickyColumns.includes('actions')}"
                [style.visibility]="hasRowActions() && !tableSettings?.parentTable && tableSettings?.child && stickyColumns.includes('actions') && 'hidden'"
              >
                @if (tableSettings?.inlineEdit && editRow === row) {
                  <naris-icon
                    class="naris-table-actions-edit"
                    icon="check_circle"
                    iconClass="green"
                    [matTooltip]="'Bestätigen' | translate"
                    matTooltipPosition="above"
                    matTooltipShowDelay="200"
                    (click)="$event.stopPropagation(); onConfirmEdit(row)"
                  />
                  <naris-icon
                    class="naris-table-actions-edit"
                    icon="cancel"
                    iconClass="warn"
                    [matTooltip]="'cancel' | translate"
                    matTooltipPosition="above"
                    matTooltipShowDelay="200"
                    (click)="$event.stopPropagation(); onCancelEdit()"
                  />
                } @else {
                  @for (action of row.actions; track $index) {
                    <naris-button
                      [icon]="getIconType(action.name)"
                      [matTooltip]="(action.label || action.name) | translate"
                      matTooltipPosition="above"
                      matTooltipShowDelay="200"
                      size="small"
                      color="transparent"
                      [disabled]="(!action.href && action.name !== 'hamburger_menu') || action.layouthint?.includes('readonly')"
                      (clicked)="$event.stopPropagation(); onClickAction(action, row, $event)"
                      [ngStyle]="{'display': action.layouthint?.includes('hide') && 'none'}"
                      [id]="'Table' + action.name"
                    />
                  }
                }
              </td>
            </ng-container>
          }
          @if (hasRowActions() && tableSettings?.child && stickyColumns.includes('actions')) {
            <ng-container
              matColumnDef="actions_sticky"
              [sticky]="stickyColumns.includes('actions')"
              [stickyEnd]="stickyColumns.includes('actions')"
            >
              <th
                mat-header-cell
                *matHeaderCellDef
                class="naris-table-actions-column nested-action"
                [ngClass]="{sticky: stickyColumns.includes('actions')}"
                [style.width.px]="actionColumnHeaderElement ? actionColumnHeaderElement.nativeElement.offsetWidth : ''"
              >
                <div class="naris-table-th">
                  {{'table.actions' | translate}}
                  <naris-button
                    size="small"
                    icon="pin"
                    color="transparent"
                    class="naris-table-pin"
                    [active]="stickyColumns.includes('actions')"
                    [matTooltip]="(stickyColumns.includes('actions') ? 'table.detach' : 'table.attach') | translate"
                    matTooltipPosition="above"
                    matTooltipShowDelay="200"
                    (click)="$event.stopPropagation(); stick('actions');"
                    id="TableChildAttachDetach"
                  />
                </div>
              </th>
              <td
                mat-cell
                *matCellDef="let row;"
                class="naris-table-actions-column nested-action"
                [ngClass]="{sticky: stickyColumns.includes('actions')}"
                [style.width.px]="actionColumnHeaderElement ? actionColumnHeaderElement.nativeElement.offsetWidth : ''"
              >
                @if (tableSettings?.inlineEdit && editRow === row) {
                  <naris-icon
                    class="naris-table-actions-edit"
                    icon="check_circle"
                    iconClass="green"
                    [matTooltip]="'Bestätigen' | translate"
                    matTooltipPosition="above"
                    matTooltipShowDelay="200"
                    (click)="$event.stopPropagation(); onConfirmEdit(row)"
                  />
                  <naris-icon
                    class="naris-table-actions-edit"
                    icon="cancel"
                    iconClass="warn"
                    [matTooltip]="'cancel' | translate"
                    matTooltipPosition="above"
                    matTooltipShowDelay="200"
                    (click)="$event.stopPropagation(); onCancelEdit()"
                  />
                } @else {
                  @for (action of row.actions; track $index) {
                    <div>
                      @if (action.name !== 'hamburger_menu') {
                        <naris-button
                          [icon]="getIconType(action.name)"
                          [matTooltip]="(action.label || action.name) | translate"
                          matTooltipPosition="above"
                          matTooltipShowDelay="200"
                          size="small"
                          color="transparent"
                          [disabled]="!action.href || action.layouthint?.includes('readonly')"
                          (clicked)="$event.stopPropagation(); onClickAction(action, row, $event)"
                          [ngStyle]="{'display': action.layouthint?.includes('hide') && 'none'}"
                          [id]="'Table' + action.name"
                        />
                      }
                      @if (action.name === 'hamburger_menu') {
                        <naris-button
                          icon="hamburger"
                          [matTooltip]="(action.label || action.name) | translate"
                          matTooltipPosition="above"
                          matTooltipShowDelay="200"
                          size="small"
                          color="transparent"
                          [matMenuTriggerFor]="rowMenu"
                          #menuTrigger="matMenuTrigger"
                          (mouseenter)="menuTrigger.openMenu()"
                          (mouseleave)="hamburgerCloseActionMenu(menuTrigger)"
                          [id]="'Table' + action.name"
                        />
                      }
                      <mat-menu #rowMenu="matMenu" [hasBackdrop]="false" xPosition="before" class="naris-table-menu">
                        <div (mouseenter)="menuOpenActionMenu()" (mouseleave)="menuCloseActionMenu()">
                          @for (childAction of action.children; track $index) {
                            <div (click)="$event.stopPropagation(); onClickAction(childAction, row, $event)" class="action-menu-item" tabindex="0">
                              <naris-icon [icon]="getIconType(childAction.name)" iconClass="small" />
                              <button mat-menu-item type="button">{{childAction.label || childAction.name | translate}}</button>
                            </div>
                          }
                        </div>
                      </mat-menu>
                    </div>
                  }
                }
              </td>
            </ng-container>
          }
          <ng-container matColumnDef="childTable">
            <td mat-cell class="child-table-definition" *matCellDef="let parentRow;" [attr.colspan]="columnsToDisplay.length">
              @if (tableSettings?.parentTable || (tableSettings?.nestedTable && !!getChildUrls(parentRow)?.length)) {
                <div
                  class="child-table"
                  [@rowExpand]="expandedChildren.includes(parentRow) && !loadingChildren.includes(parentRow) ? 'expanded' : 'collapsed'"
                >
                  @if (tableSettings?.parentTable && loadedChildren.includes(parentRow)) {
                    <naris-table
                      class="child-table-inner"
                      [parentID]="parentRow._id"
                      [dataUrl]="dataUrl.split('?')[0]"
                      [tableConfig]="{
                        heading: false,
                        pagination: false,
                        child: true,
                        parentTable: true,
                        modalChild: tableSettings?.child ? tableSettings?.modalChild : isModal,
                        expandChildren: tableSettings?.child ? !!tableSettings?.expandChildren : !!tableLayouthint?.includes('expand-children')
                      }"
                      [rootColumns]="rootColumns"
                      [columnsToDisplay]="columnsToDisplay"
                      [activeColumns]="activeColumns"
                      [lookupTokenParam]="isModal ? dataSource.getLookupTokenParam(dataUrl)! : lookupTokenParam"
                      [childTableIndents]="childTableIndents + 1"
                      [rootWidth]="rootWidth"
                      (childLoaded)="removeLoadingChild(parentRow)"
                      (rowClicked)="rowClicked.emit($event)"
                      (loaded)="tableConfig.isWizard && loaded.emit(true)"
                      (refreshParent)="reload()"
                    />
                  }
                  @if (tableSettings?.nestedTable && loadedChildren.includes(parentRow) && !tableLayouthint?.includes('nested-is-frequency')) {
                    @for (childUrl of getChildUrls(parentRow); track $index) {
                      <naris-table
                        class="child-table-inner"
                        [dataUrl]="childUrl"
                        [tableConfig]="{
                          heading: true,
                          options: ['filter', 'arrange'],
                          pagination: true,
                          child: true,
                          nestedTable: true,
                          modalChild: tableSettings?.child ? tableSettings?.modalChild : isModal,
                          expandChildren: tableSettings?.child ? !!tableSettings?.expandChildren : !!tableLayouthint?.includes('expand-children')
                        }"
                        [lookupTokenParam]="isModal ? dataSource.getLookupTokenParam(dataUrl)! : lookupTokenParam"
                        [childTableIndents]="childTableIndents + 1"
                        [rootWidth]="rootWidth"
                        (childLoaded)="removeLoadingChild(parentRow)"
                        (rowClicked)="rowClicked.emit($event)"
                        (loaded)="tableConfig.isWizard && loaded.emit(true)"
                        (refreshParent)="reload()"
                      />
                    }
                  }
                </div>
              }
            </td>
          </ng-container>
          <ng-container matColumnDef="editTable">
            <td 
              mat-cell
              class="edit-table-definition" 
              *matCellDef="let parentRow;" 
              [attr.colspan]="columnsToDisplay.length"
            >
              @if (!!getUpdateEndpoint(parentRow) && expandedEditChildren.includes(parentRow)) {
                <div
                  class="child-table edit"
                  [@rowExpand]="expandedEditChildren.includes(parentRow)? 'expanded' : 'collapsed'"
                >
                  <mat-expansion-panel
                    class="dynamic-table__expansion__panel"
                    [style.width.px]="tableSettings?.child ? rootWidth - (childTableIndents * 33) : null"
                    [ngClass]="{'dynamic-table__expansion__panel--expanded': expansionEditPanel.expanded}"
                    [expanded]="true"
                    [ngStyle]="{'width.px': rootContainer.offsetWidth - 4}"
                    hideToggle
                    #expansionEditPanel
                  >
                    <mat-expansion-panel-header 
                      [ngClass]="{'dynamic-table__expansion__header--expanded': expansionEditPanel.expanded}"
                      (click)="toggleEdit(parentRow)"
                    >
                      <mat-panel-title class="dynamic-table__expansion__header">
                        <naris-button
                          [icon]="'cancel-edit'"
                          color="default"
                          size="small"
                          [matTooltip]="'close' | translate"
                          id="TableClose"
                        />
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <naris-card>
                      <app-form
                        [endpoint]="getUpdateEndpoint(parentRow)!"
                        [isEmbedded]="true"
                        [isEmbeddedInTable]="true"
                        [isDynamic]="true"
                        (dynamicSaved)="handleSaved($event, expansionEditPanel, 'edit', parentRow)"
                        [parentRow]="parentRow"                  
                        [initAfterReset]="!layoutHint.includes('only-one')"
                        [expanded]="expansionEditPanel.expanded"
                        [tableLayouthint]="layoutHint"
                      />
                    </naris-card>
                  </mat-expansion-panel>
                </div>
              }
            </td>
          </ng-container>
          <ng-container matColumnDef="showRichText">
            <td 
              mat-cell
              class="show-rich-text-definition"
              *matCellDef="let parentRow;" 
              [attr.colspan]="columnsToDisplay.length"
            >
              @if (hasRichTextChild(parentRow)) {
                <div
                  class="child-table edit rich-text"
                  [@rowExpand]="hasRichTextChild(parentRow) ? 'expanded' : 'collapsed'"
                >
                  <naris-card class="show-rich-text-definition__card">
                    <span [innerHTML]="showRichText(parentRow) | safeHtml:['class', 'data-doclink-revision', 'data-doclink-hash']" narisDoclink></span>
                  </naris-card>
                </div>
              }
            </td>
          </ng-container>
          <ng-container matColumnDef="noResult">
            <td
              class="no-result-cell"
              mat-footer-cell 
              *matFooterCellDef 
              [attr.colspan]="columnsToDisplay.length"
            >
              @if (isEmpty && !loading && !error && !dataSource.activeFilters.length && !refreshing && !tableSettings?.child) {
                <div
                  class="naris-table-empty-state"
                  [ngClass]="{'naris-table-empty-border': !tableCreateAction && !lookupEndpoint && !!tableActions, 'expanded': expanded}"
                >
                  <naris-empty-state
                    [text]="'table.no_items_of_kind' | translate"
                    [image]="'empty'"
                    [padding]="0"
                  />
                </div>
              }
              @if (!!tableCreateAction || !!lookupEndpoint || !!tableWizardAction) {
                <mat-expansion-panel 
                  class="dynamic-table__expansion__panel"
                  [style.width.px]="tableSettings?.child ? rootWidth - (childTableIndents * 33) : null"
                  [ngClass]="{'dynamic-table__expansion__panel--expanded': expansionEmptyPanel.expanded}"
                  [expanded]="isWizard"
                  hideToggle
                  (opened)="expanded = true"
                  (closed)="expanded = false"
                  #expansionEmptyPanel
                >
                  <mat-expansion-panel-header 
                    [ngClass]="{'dynamic-table__expansion__header--expanded': expansionEmptyPanel.expanded}"
                    (click)="onOpenWizard()"
                  >
                    <mat-panel-title class="dynamic-table__expansion__header">
                      <naris-button
                        [icon]="!!tableWizardAction ? 'open_add' : 'add'"
                        color="default"
                        size="small"
                        [matTooltip]="(expansionEmptyPanel.expanded ? 'cancel' : 'add_new') | translate"
                        [class.expanded]="expansionEmptyPanel.expanded"
                        id="TableCancelAddNewNoResult"
                      />
                    </mat-panel-title>
                  </mat-expansion-panel-header>
                  @if (!tableWizardAction) {
                    <naris-card>
                      <app-form
                        [endpoint]="tableCreateAction?.href || lookupEndpoint"
                        [isEmbedded]="true"
                        [isDynamic]="true"
                        [isEmbeddedInTable]="true"
                        [tabHref]="tabHref"
                        [initAfterReset]="!layoutHint.includes('only-one')"
                        (dynamicSaved)="handleSaved($event, expansionEmptyPanel, 'empty')"
                        [expanded]="expansionEmptyPanel.expanded"
                        [tableLayouthint]="layoutHint"
                      />
                    </naris-card>
                  }
                </mat-expansion-panel>
              }
            </td>
          </ng-container>
          <tr
            mat-header-row
            *matHeaderRowDef="columnsToDisplay; sticky: !tableSettings?.child;"
            class="naris-table-tr"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; let odd = odd; let last = last; columns: columnsToDisplay;"
            class="naris-table-tr"
            [ngClass]="{
              oddrow: odd, 
              lastrow: last, 
              clickable: (isModal || clickable || tableSettings?.modalChild) && enableRowClick, 
              blurred: refreshing,
              highlighted: row._id === highlightRow?.row
            }"
            [formGroup]="rowForm"
            (click)="onRowClicked(row)"
          ></tr>
          <tr
            mat-row
            *matRowDef="let parentRow; columns: ['childTable']"
            class="naris-table-tr child-table-tr"
            [ngClass]="{closed: refreshing}"
          ></tr>
          <tr
            mat-row
            *matRowDef="let parentRow; columns: ['editTable']"
            class="naris-table-tr child-table-tr"
            [ngClass]="{closed: refreshing}"
          ></tr>
          <tr
            mat-row
            *matRowDef="let parentRow; columns: ['showRichText']"
            class="naris-table-tr child-table-tr"
            [ngClass]="{closed: refreshing}"
          ></tr>
          <tr
            mat-footer-row
            *matFooterRowDef="['noResult']"
            class="naris-table-tr no-result"
            [ngClass]="{closed: !isEmpty, blurred: refreshing}"
          ></tr>
        </table>
      </div>
    }
    @if (tableLayouthint?.includes('list-summary')) {
      <div class="list-summary">
        <span class="list-summary--col">
          @for (col of rootColumns; track $index) {
            <span
              class="list-summary--col--item"
            >
              <span>{{col.label}}: </span>
              @if (![true, false].includes(tableData[0]?.[col.id])) {
                <span class="data-cell">
                  {{tableData[0]?.[col.id]}}
                </span>
              } @else {
                <naris-icon
                  [icon]="tableData[0]?.[col.id] ? 'check' : 'close'"
                  [iconClass]="tableData[0]?.[col.id] ? 'green' : 'warn'"
                  class="boolean-icon"
                />
              }
            </span>
          }
        </span>
      </div>
    }
    
    @if (!loading && !isEmpty && (!!tableCreateAction || !!lookupEndpoint || !!tableWizardAction) && ((tableSettings?.parentTable && !tableSettings?.child) || !tableSettings?.parentTable)) {
      <mat-expansion-panel 
        class="dynamic-table__expansion__panel"
        [style.width.px]="tableSettings?.child ? rootWidth - (childTableIndents * 33) : null"
        [ngClass]="{
          'dynamic-table__expansion__panel--modal': isModal,
          'dynamic-table__expansion__panel--expanded': expansionPanel.expanded, 
          'dynamic-table__expansion__panel--modal-expanded': isModal && expansionPanel.expanded
        }"
        (afterExpand)="scrollInFocus(modalWrapper, true)"
        (afterCollapse)="scrollInFocus(modalWrapper, false)"
        hideToggle
        #expansionPanel
      >
        <mat-expansion-panel-header 
          [ngClass]="{'dynamic-table__expansion__header--expanded': expansionPanel.expanded}"
          (click)="onOpenWizard()"
        >
          <mat-panel-title class="dynamic-table__expansion__header">
            <!-- <span class="dynamic-table__expansion__title">{{ 'form.add_another' | translate }}</span> -->
            <naris-button
              [icon]="!!tableWizardAction ? 'open_add' : tableCreateAction?.layouthint?.includes('empty-post') ? 'playlist_add' : 'add'"
              color="default"
              size="small"
              [matTooltip]="(expansionPanel.expanded ? 'cancel' : 'add_new') | translate"
              [class.expanded]="expansionPanel.expanded"
              (click)="rowActionUrl = tableCreateAction?.href || lookupEndpoint"
              id="TableCancelAddNew"
            />
          </mat-panel-title>
        </mat-expansion-panel-header>
        @if (!tableWizardAction) {
          <naris-card>
            <app-form
              [endpoint]="tableCreateAction?.href || lookupEndpoint"
              [isEmbedded]="true"
              [isDynamic]="true"
              [isEmbeddedInTable]="true"
              [tabHref]="tabHref"
              (dynamicSaved)="handleSaved($event, expansionPanel, 'wizard')"
              [initAfterReset]="!layoutHint.includes('only-one')"
              [expanded]="expansionPanel.expanded"
              [tableLayouthint]="layoutHint"
            />
          </naris-card>
        }
      </mat-expansion-panel>
    }
  </div>
  @if (!error && !loading && tableSettings?.pagination && !layoutHint.includes('only-one') && !tableLayouthint?.includes('only-one') && !tableLayouthint?.includes('list-summary') && settings.includes(tableSettingsEnum.SHOWFOOTER)) {
    <naris-paginator
      [numOfItems]="(dataSource.currentPageLength$ | async)"
      [numOfPages]="(dataSource.numPages$ | async)"
      [total]="(dataSource.totalResults$ | async)"
      [currentPage]="(dataSource.currentPage$ | async)"
      [pagesize]="dataSource.pagingMeta.pagesize"
      [pagesizeOptions]="dataSource.pagingMeta.pagesizeOptions"
      [refreshActive]="refreshing"
      [currentPageSlice]="(dataSource.currentPageSlice$ | async)"
      [pageSlices]="(dataSource.pageSlices$ | async)"
      [ngClass]="{'dashboard-widget-paginator': isOverviewDashboard}"
      (toFirstPage)="dataSource.firstPage(); dataSource.optionsChanged = true;"
      (toLastPage)="dataSource.lastPage(); dataSource.optionsChanged = true;"
      (toNextPage)="dataSource.nextPage(); dataSource.optionsChanged = true;"
      (toPreviousPage)="dataSource.previousPage(); dataSource.optionsChanged = true;"
      (toPage)="dataSource.toPage($event); dataSource.optionsChanged = true;"
      (toNextSlice)="dataSource.toNextSlice(); dataSource.optionsChanged = true;"
      (toPreviousSlice)="dataSource.toPreviousSlice(); dataSource.optionsChanged = true;"
      (pagesizeChange)="changePageSize($event); dataSource.optionsChanged = true;"
      (buttonClicked)="onClickPaginator($event)"
    />
  }
</div>

@if (error && !loading && !tableSettings?.child) {
  <div class="naris-table-empty-state">
    <naris-icon icon="error" iconClass="medium accent" />
    <p>{{'table.dat_not_loaded' | translate}}</p>
  </div>
}

<div [ngClass]="{closed: error || !loading || tableSettings?.child}">
  <div class="naris-table-loader">
    <naris-progress-spinner [strokeWidth]="2" [diameter]="24" />
  </div>
</div>
