import { Component, Input, ViewEncapsulation, OnChanges, SimpleChanges, HostBinding, OnInit, Output, EventEmitter, OnDestroy, AfterViewInit, ViewChild, ElementRef, forwardRef } from '@angular/core';
import { CdkDragDrop, moveItemInArray, CdkDropList, CdkDrag, CdkDragPlaceholder, CdkDragHandle } from '@angular/cdk/drag-drop';
import { SelectionModel } from '@angular/cdk/collections';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { firstValueFrom, of, Subscription } from 'rxjs';
import { catchError, distinctUntilChanged, skip } from 'rxjs/operators';
import { TableService, FormService, DialogService, SnackbarService, BeinformedService, UserService, HttpService, LanguageService, TaskgroupService, FileService, NarisBreadcrumbService, TabService } from '@core/services';
import { TableDataSource, FilterChip } from '@core/classes';
import { STATUS_CONFIG, CON_FUNCS, TableSettings } from '@core/constants';
import { rowExpand, filterExpand } from '@shared/animations/table.animations';
import { clone, decodeRich, durationCompact, getIcon, hexToRgb, roundTo, truncate } from '@core/helpers';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { FrequencyService } from '@core/services/frequency.service';
import { MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle } from '@angular/material/expansion';
import { BreadcrumbTabsService } from '@core/services/breadcrumb-tab.service';
import { FooterToolbarService } from '@core/services/footer-toolbar.service';
import { TableSettingsService } from '@core/services/table-settings.service';
import { StrategyMapService } from '@core/services/strategy-map.service';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { NgStyle, NgClass, AsyncPipe } from '@angular/common';
import { MatTooltip } from '@angular/material/tooltip';
import { MatChipListbox, MatChipOption, MatChipRemove } from '@angular/material/chips';
import { MatTable, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatCellDef, MatCell, MatFooterCellDef, MatFooterCell, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatFooterRowDef, MatFooterRow } from '@angular/material/table';
import { MatSort, MatSortHeader } from '@angular/material/sort';
import { CdkOverlayOrigin, CdkConnectedOverlay } from '@angular/cdk/overlay';
import { SafeHtmlPipe } from '@shared/pipes/safe-html.pipe';
import { RichTextPipe } from '@shared/pipes/rich-text.pipe';
import { ToolbarComponent } from '../toolbar/toolbar.component';
import { ToolbarItemComponent } from '../toolbar/toolbar-item/toolbar-item.component';
import { ButtonComponent } from '../../elements/button/button.component';
import { FormInputComponent } from '../../../core/form/form-input/form-input.component';
import { IconComponent } from '../../elements/icon/icon.component';
import { SlidetoggleComponent } from '../../elements/slidetoggle/slidetoggle.component';
import { CheckboxComponent } from '../../elements/checkbox/checkbox.component';
import { CardComponent } from '../card/card.component';
import { FormComponent } from '../../../core/form/form.component';
import { DoclinkDirective } from '../../directives/doclink.directive';
import { EmptyStateComponent } from '../empty-state/empty-state.component';
import { PaginatorComponent } from '../paginator/paginator.component';
import { ProgressSpinnerComponent } from '../../elements/progress-spinner/progress-spinner.component';
import { InfoDialogComponent } from '../info-dialog/info-dialog.component';
import type { ITableSettings, ITableDataMapped, IDataOptions, IWidgetConfig, ICaseListRow, IRootColumn, IAction, TValOrArr, ICaseListAction, IFormResponse, IFormAnchor, ILayoutTab, IFormLookupCreateSuccess } from '@core/models';

@Component({
  selector: 'naris-table',
  styleUrls: ['./table.component.scss'],
  templateUrl: './table.component.html',
  encapsulation: ViewEncapsulation.None,
  animations: [rowExpand, filterExpand],
  standalone: true,
  imports: [forwardRef(() => InfoDialogComponent), ToolbarComponent, ToolbarItemComponent, FormsModule, NgStyle, MatTooltip, ButtonComponent, MatMenuTrigger, MatMenu, ReactiveFormsModule, forwardRef(() => FormInputComponent), CdkDropList, CdkDrag, NgClass, CdkDragPlaceholder, IconComponent, CdkDragHandle, SlidetoggleComponent, MatChipListbox, MatChipOption, MatChipRemove, MatTable, MatSort, MatColumnDef, MatHeaderCellDef, MatHeaderCell, MatSortHeader, CheckboxComponent, MatCellDef, MatCell, CdkOverlayOrigin, CdkConnectedOverlay, MatMenuItem, MatExpansionPanel, MatExpansionPanelHeader, MatExpansionPanelTitle, CardComponent, FormComponent, DoclinkDirective, MatFooterCellDef, MatFooterCell, EmptyStateComponent, MatHeaderRowDef, MatHeaderRow, MatRowDef, MatRow, MatFooterRowDef, MatFooterRow, PaginatorComponent, ProgressSpinnerComponent, AsyncPipe, SafeHtmlPipe, TranslateModule, RichTextPipe]
})
export class TableComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {

  @ViewChild('modalWrapper') public modalWrapper: ElementRef<HTMLDivElement>;

  @Input() public rootColumns: IRootColumn[] | undefined = [];
  @Input() public columnsToDisplay: string[] = [];
  public dataSource = new TableDataSource(this.tableService, this.beinformedService, this.formService, this.userService, this.translateService, this.tableSettingsService);
  public selection = new SelectionModel<ICaseListRow>(true, []);
  public stickyColumns = ['actions'];
  public expandedChildren: ICaseListRow[] = [];
  public expandedEditChildren: ICaseListRow[] = [];
  public expandedRichTextChildren: { row: ICaseListRow; columnId: number | string }[] = [];
  public loadedChildren: ICaseListRow[] = [];
  public loadingChildren: ICaseListRow[] = [];
  public editRow: ICaseListRow | null = null;
  public editables: string[] = [];
  public showFilter = false;
  public activeOption: string | null = null;
  @Input() public activeColumns: IRootColumn[] = [];
  public tableTitle = '';
  public tableName = '';
  public tableLink = '';
  public tableActions: ICaseListAction[] = [];
  public tableCreateAction: ICaseListAction | undefined;
  public tableWizardAction: ICaseListAction | undefined;
  public tableEmptyPostAction: ICaseListAction | undefined;
  public tableUpdateAssignmentsAction: ICaseListAction | undefined;
  public tableMarkAllAsRead: ICaseListAction | undefined;
  public filterChips = [];
  public rowForm = new FormGroup({});
  public sortableColumns: string[] = [];
  public loading = true;
  public refreshing = false;
  public isEmpty = false;
  public error = false;
  public stateCaseColors = STATUS_CONFIG;
  public previousWidgetConfig: IWidgetConfig;
  public newWidgetConfig: IWidgetConfig;
  public titleEdit = false;
  public titleInput = '';
  public readonly separatorKeysCodes = [ENTER, COMMA] as const;
  public tableLayouthint: string[] | undefined;
  public maxValLength = 100;
  private columnsToggled = false;
  private formMeta = {} as IFormAnchor;
  private formTokens: string[] = [];
  public data: any[] = [];
  public updateEndpoint: string;
  public rowActionUrl: string;
  public isMessagesTable = false;
  public isWizard = false;
  public showDescriptionOverlay = false;
  private tableResourceType: string;
  private readonly subs: Subscription[] = [];
  private iconColumName: string | null;
  private iconColumNameOptions: Record<string, any>[];
  private updateActionHref: string;
  private removeTabHref: string | null | undefined;
  public expanded: boolean;
  private erasedFilters = false;
  private timeout: NodeJS.Timeout;
  private menuTrigger: MatMenuTrigger;
  private currentRow: ICaseListRow | undefined;
  private refreshPanels = false;
  private baseFilters: FilterChip[] = [];
  public highlightRow: {url: string; row: number} | undefined;
  @ViewChild('actionColumnHeader') public actionColumnHeaderElement: ElementRef;
  @Input() public layoutHint: string[] = [];

  @HostBinding('class') public rootClass = 'naris-table';

  @Input() public detailUrl: string;
  @Input() public actionBaseUrl = '';
  @Output() public readonly rowClicked = new EventEmitter<ICaseListRow>();
  @Output() public readonly submitSelection = new EventEmitter<any>();
  @Output() public readonly childLoaded = new EventEmitter<any>();
  @Output() public readonly widgetConfigChanged = new EventEmitter<any>();
  @Input() public isModal: boolean;
  @Input() public tableData = {} as Record<string, any>;
  @Input() public tableColumnsObj: Record<string, any>[];
  @Input() public dataUrl = '';
  @Input() public tableConfig = {} as ITableSettings;
  @Input() public preSelected: ICaseListRow[] = [];
  @Input() public parentID = 0;
  @Input() public editMode = false;
  @Input() public widgetConfig: IWidgetConfig;
  @Input() public lookupTokenParam: string;
  @Input() public createAction = false;
  @Input() public lookupEndpoint = '';
  @Input() public redirectMessage: string;
  @Input() public selectedTab: ILayoutTab;
  @Input() public tabHref: string;
  @Input() public childTableIndents = 0;
  @Input() public rootWidth: number;
  @Input() public enableRowClick = true;

  @Input() public settings: TableSettings[] = [
    TableSettings.SHOWHEADER, 
    TableSettings.SHOWFOOTER, 
    TableSettings.SHOWSORTING, 
    TableSettings.SHOWPIN
  ];
  get tableSettingsEnum(): typeof TableSettings {
    return TableSettings; 
  }

  // Dynamic dashboard
  @Input() public isOverviewDashboard: boolean;
  @Input() public widgetPanelSize: { width: number | undefined; height: number | undefined } | null;

  @Input()
  set reloadTable(value: boolean) {
    if (value) this.reload();
  }

  @Input() set pagesize(value: number) {
    if (value) this.dataSource.changePageSize(value);
  }

  @Input()
  public isTableReload = false;

  @Output() public readonly pagesizeChanged = new EventEmitter<number>();

  @Output()
  public readonly loaded = new EventEmitter<boolean>();

  @Output()
  public readonly createActionClicked = new EventEmitter<any>();

  @Output()
  public readonly refreshParent = new EventEmitter<boolean>();

  @Output()
  public readonly lookupCreateSaved = new EventEmitter<IFormLookupCreateSuccess>();

  @Output()
  public readonly closeLookup = new EventEmitter<void>();

  public tableConfigDefault: ITableSettings = {
    heading: true,
    check: false,
    options: ['filter', 'arrange'],
    pagination: true,
    sorting: true,
    pagesize: 10,
    child: false,
    inlineEdit: false,
    parentTable: false,
    nestedTable: false,
    contextFilter: false,
    modalChild: false,
    isWizard: false,
    listSummary: false
  };

  public tableSettings: ITableSettings | null;
  
  // @Inject(INFO_DIALOG_TOKEN) 
  // protected readonly infoDialogComponent = inject(INFO_DIALOG_TOKEN);//: ComponentType<InfoDialogComponent>,

  constructor(
    public router: Router,
    protected readonly tableService: TableService,
    protected readonly httpService: HttpService,
    protected readonly formService: FormService,
    protected readonly snackbar: SnackbarService,
    protected readonly dialogService: DialogService,
    protected readonly beinformedService: BeinformedService,
    protected readonly userService: UserService,
    protected readonly fileService: FileService,
    protected readonly languageService: LanguageService,
    protected readonly taskgroupService: TaskgroupService,
    protected readonly narisBreadcrumb: NarisBreadcrumbService,
    protected readonly dialog: MatDialog,
    protected readonly translateService: TranslateService,
    protected readonly frequencyService: FrequencyService,
    protected readonly breadcrumbTabService: BreadcrumbTabsService,
    protected readonly footerToolbarService: FooterToolbarService,
    protected readonly tableSettingsService: TableSettingsService,
    protected readonly tabs: TabService,
    protected readonly strategyMapService: StrategyMapService
  ) {}

  public ngOnChanges(changes: SimpleChanges) {
    if (
      !!changes.dataUrl?.currentValue &&
      !!changes.dataUrl?.previousValue &&
      changes.dataUrl.currentValue !== changes.dataUrl.previousValue
    ) {
      this.tableCreateAction = undefined;
      const pagesize = this.dataSource.pagingMeta.pagesizeOptions ? this.dataSource.pagingMeta.pagesize : 0;
      this.initializeTable(changes.dataUrl.currentValue, { pagesize });
    }

    if (!this.tableConfig.child && !!this.modalWrapper) this.rootWidth = this.modalWrapper.nativeElement.offsetWidth;
  }

  public ngOnInit() {
    void this.init();
    this.subs.push(
      this.router.events.subscribe(() => setTimeout(() => this.isWizard = this.router.url.includes('-wizard')))
    );
  }

  public async init() {
    await firstValueFrom(this.tableSettingsService.getTableOptions(this.dataUrl)).then(config => {
      if (!!config) this.dataSource.setTableConfiguration(config);
    });
    this.isWizard = this.router.url.includes('-wizard');
    this.tableSettings = { ...this.tableConfigDefault, ...this.tableConfig };

    if (!this.tableConfig.child && !!this.modalWrapper) this.rootWidth = this.modalWrapper.nativeElement.offsetWidth;
    
    this.dataSource.data$.subscribe(val => {
      this.data = val;
      if (this.isModal && !!this.preSelected?.length) this.preSelect(val);
    });
    this.dataSource.loading$.pipe(distinctUntilChanged()).subscribe(val => {
      if (this.tableSettings?.child && !val) this.childLoaded.emit(this.data.length);
      this.loading = val;
    });
    this.dataSource.refreshing$.pipe(distinctUntilChanged()).subscribe(val => this.refreshing = val);
    this.dataSource.isEmpty$.pipe(distinctUntilChanged()).subscribe(val => this.isEmpty = val);
    this.dataSource.error$.pipe(distinctUntilChanged()).subscribe(val => this.error = val);
    this.dataSource.tableData$.subscribe((res: ITableDataMapped) => {
      const tableData = res.tableData;
      const tableDataSource = res.tableDataSource;
      const tableColumns = res.tableColumns;
      this.tableData = tableData;

      if (!!tableData?.[0]?._links) {
        const foundFreqName = Object.getOwnPropertyNames(tableData?.[0]?._links).find(prop => prop.includes('Frequency'));
        const foundFreqUnitName = Object.getOwnPropertyNames(tableData?.[0]).find(prop => prop.includes('FrequencyUnit'));
        if (foundFreqName) {
          tableData.forEach(dataRow => {
            const href = dataRow._links[foundFreqName]?.href;
            this.beinformedService.fetchResponseWithContributions<'caselist'>(href).subscribe(result => {
              const frequency = this.beinformedService.extractResults(result)?.[0];
              const frequencyString = this.frequencyService.frequencyString(frequency).replace(/(<([^>]+)>)/gi, ''); // frequency string and strip HTML tags
              dataRow['Frequency'] = frequencyString;
              const tableDataSourceRow = tableDataSource.find(row => row._id === dataRow._id);
              if (!!tableDataSourceRow) tableDataSourceRow.Frequency = frequencyString;
              if (!tableColumns.find(col => Object.getOwnPropertyNames(col).includes('Frequency'))) {
                const freqColumn = {Frequency: {columns: 50, formatted: true, label: this.translateService.instant('table.frequency'), maxLength: 200, rows: 5, type: 'string'}};
                tableColumns.push(freqColumn);
              }
              
              this.setData(res);
            });
          });
        } else if (!!foundFreqUnitName && !!tableData?.length) {
          const frequency = tableData[0];
          const frequencyString = this.frequencyService.frequencyString(frequency).replace(/(<([^>]+)>)/gi, ''); // frequency string and strip HTML tags
          tableData.forEach(row => row['Frequency'] = frequencyString);
          tableDataSource.forEach(row => row['Frequency'] = frequencyString);
          this.setFrequencyColumns(tableColumns);
        }
      } else {
        const foundFreqUnitName = tableColumns.find(column => Object.getOwnPropertyNames(column).includes('FrequencyUnit'));
        if (!!foundFreqUnitName) {
          this.setFrequencyColumns(tableColumns);
        }
      }
      this.setData(res);
    });
    if (!this.tableConfig.child || this.tableSettings.nestedTable) {
      this.dataSource.filterChips$.pipe(skip(1)).subscribe((filters: FilterChip[]) => {
        if (JSON.stringify(this.baseFilters) === JSON.stringify(filters)) return;
        this.baseFilters = filters;
        this.dataSource.resetPages = true;
        const dataOptions = this.dataSource.getCurrentDataOptions();
        if (filters.length === 0) {
          this.dataSource.getTableData(this.dataUrl, this.isModal, dataOptions, true).subscribe();
        } else {
          this.dataSource.getTableData(this.dataUrl, this.isModal, {...dataOptions, page: this.dataSource.isGridTable ? 0 : 1}, true).subscribe();
        }

        if (!!this.widgetConfig) this.newWidgetConfig = { ...this.newWidgetConfig, filters };
      });
      this.dataSource.sortingMeta$.subscribe(sorting => {
        if (!!this.widgetConfig) this.newWidgetConfig = { ...this.newWidgetConfig, sorting };
      });
    }
    if (!!this.widgetConfig) this.setWidgetConfig(this.widgetConfig);
    this.setDataUrl();
    const filter = !!this.dataSource.tableConfiguration?.settings?.filter ? this.dataSource.createFilterString(this.dataSource.tableConfiguration.settings.filter) : '';
    const sort = !!this.dataSource.tableConfiguration?.settings?.options ? this.dataSource.tableConfiguration.settings.options.sort : '';
    let pagesize: number | undefined = 0;
    if (!!this.dataSource.tableConfiguration?.settings?.options) pagesize = this.dataSource.tableConfiguration.settings.options.pagesize;
    else pagesize = this.tableSettings.parentTable ? 0 : this.tableSettings.pagesize;
    this.initializeTable(this.dataUrl, { pagesize, filter, sort });
    this.subs.push(
      this.tableService.updateEditedRow$.subscribe(res => {
        if (this.rowActionUrl === res)
          this.reload(false);
      })
    );
    this.tableService.targetedTableReload$.subscribe(target => {
      if (this.tableTitle.toLowerCase() === target) this.dataSource.getTableData(this.dataUrl, this.isModal, { pagesize }, true).subscribe();
    });
    if (this.tabHref?.includes('strategy-map') && this.tabHref?.endsWith('/related-data')) {
      this.strategyMapService.refreshTables$.subscribe(() => this.reload(false));
    }
  }

  public ngAfterViewInit(): void {
    if (this.isEmpty) this.loading = false;
  }

  public ngOnDestroy(): void {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  private setFrequencyColumns(tableColumns: Record<string, any>[]) {
    tableColumns.splice(0, tableColumns.length);
    const freqColumn = { Frequency: { columns: 50, formatted: true, label: this.translateService.instant('table.frequency'), maxLength: 200, rows: 5, type: 'string' } };
    const freqEditColumn = { FrequencyEditable: { columns: 50, formatted: true, label: this.translateService.instant('table.frequency_editable'), type: 'boolean' } };
    tableColumns.push(freqColumn);
    tableColumns.push(freqEditColumn);
  }

  private setData({
    tableData,
    tableLink,
    tableTitle,
    tableName,
    tableActions,
    tableColumns,
    sortingMeta,
    tableResourceType
  }: ITableDataMapped) {
    this.tableLink = tableLink;
    this.tableActions = tableActions.filter(action => action.name !== 'update-default-assignments-for-context');
    this.tableWizardAction = tableActions.find(action => action.layouthint?.includes('wizard'));
    this.tableEmptyPostAction = tableActions.find(action => action.layouthint?.includes('empty-post'));
    this.tableCreateAction = tableActions.find(action => action.type === 'create' && !action.layouthint?.includes('wizard') && action.name !== 'set-all-notifications-as-read');
    this.tableUpdateAssignmentsAction = tableActions.find(action => action.name === 'update-default-assignments-for-context'); // LET OP! vreemde eend!
    this.tableMarkAllAsRead = tableActions.find(action => action.name === 'set-all-notifications-as-read');
    this.tableColumnsObj = tableColumns;
    this.sortableColumns = sortingMeta?.attributes || [];
    this.tableResourceType = tableResourceType;
    this.tableName = tableName;
    if (!this.widgetConfig?.title) {
      this.tableTitle = tableTitle;
      this.titleInput = tableTitle;
    }
    this.setColumns({ tableColumns, tableData } as ITableDataMapped);
    this.isMessagesTable = tableLink.endsWith('/messages') || tableLink.endsWith('/information-notifications');
  }


  public isAllSelected = () => {
    const uncheckedFound = this.data.find(row => !this.selection.selected.some(item => item._id === row._id)); // if at least one unchecked item found then isAllSelected is false
    return !uncheckedFound;
  };

  public isIndeterminate() {
    const checkedFound = this.data.find(row => this.selection.selected.some(item => item._id === row._id)); // this.data.find(row => this.selection.isSelected(row._id) === true);
    return !this.isAllSelected() && checkedFound;
  }

  private async getFirstValueFrom() {
    return firstValueFrom(this.tableSettingsService.getTableOptions(this.dataUrl));
  }

  public masterToggle = () => {
    // first clear the already selected items (the old items). this.selection.clear() would clear the whole selection everytime select all is clicked
    this.selection.selected.forEach(old => {
      if (Object.keys(old).includes('value')) { // check if item has property 'value', that is an 'old' item. newly selected items contain the properties: actions, _id and _links
        this.selection.deselect(old);
      }
    });
    this.isAllSelected() ?
      this.data.forEach(row => this.selection.deselect(row)) :
      this.data.forEach(row => this.selection.select(row)); // chang row to row._id, because SelectionModel.isSelected doesn't handle objects very well
  };

  public checkboxLabel(row?: ICaseListRow): string {
    if (!row) return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row._id}`;
  }

  public drop = (event: CdkDragDrop<string[]>) => {
    const rcs = [...this.rootColumns!];
    moveItemInArray(rcs, event.previousIndex, event.currentIndex);
    rcs.forEach((col, i) => col.order = i);
    this.rootColumns = [...rcs.map(c => ({ ...c }))];
    this.dataSource.tableConfiguration.tableUrl = this.dataUrl;
    this.tableSettingsService.saveColumnConfiguration(this.dataUrl, this.dataSource.tableConfiguration, this.rootColumns);
    this.columnsToDisplay = this.sortColumns(rcs, this.hasRowActions()) as string[];
    if (!!this.widgetConfig) this.newWidgetConfig = { ...this.newWidgetConfig, rootColumns: rcs };
  };

  public stick = (id: string) => {
    if (this.stickyColumns.includes(id)) {
      this.stickyColumns.splice(this.stickyColumns.indexOf(id), 1);
      if (this.tableSettings?.child && id === 'actions') this.columnsToDisplay.splice(this.columnsToDisplay.indexOf('actions_sticky'), 1);
    } else {
      this.stickyColumns.push(id);
      if (this.tableSettings?.child && id === 'actions') this.columnsToDisplay.push('actions_sticky');
    }
    if (!!this.widgetConfig) this.newWidgetConfig = { ...this.newWidgetConfig, stickyColumns: this.stickyColumns };
  };

  public toggleColumn = (column: IRootColumn) => {
    column.active = !column.active;
    this.columnsToDisplay = this.sortColumns(this.rootColumns!, this.hasRowActions()) as string[];
    this.activeColumns = this.rootColumns!.filter(col => col.active);
    this.columnsToggled = !!this.activeColumns;
    this.dataSource.tableConfiguration.tableUrl = this.dataUrl;
    this.tableSettingsService.saveColumnConfiguration(this.dataUrl, this.dataSource.tableConfiguration, this.rootColumns!);
    if (!!this.widgetConfig) this.newWidgetConfig = { ...this.newWidgetConfig, rootColumns: [...this.rootColumns!.map(c => ({ ...c }))] };
  };

  public onClickPaginator = (event: any) => {
    if (event.event === 'refresh') this.reload();
  };

  public trackByID = (_: number, { id }: IRootColumn) => `${id}`;

  public getIconType(inputString: string): string {
    const lowerString = inputString.toLowerCase().trim();
    return getIcon(lowerString);
  }

  get clickable(): boolean {
    return (this.isModal || !this.tableLayouthint?.includes('no-details')) && !this.editRow && !this.tableLayouthint?.includes('frequency');
  }

  public onRowClicked(row: ICaseListRow): void {
    if (!window.getSelection()?.toString() && !this.editRow && this.enableRowClick) {
      if ((this.isModal || this.tableSettings?.modalChild) && !this.tableSettings?.check) this.rowClicked.emit(row);
      else if (this.tableSettings?.check) this.toggleRow(row);
    }
  }

  public onClickAction(action: IAction, row?: ICaseListRow, event?: MouseEvent) {
    this.removeTabHref = null;
    if (!!action?.href) this.rowActionUrl = action.href; 
    if (action.name?.startsWith('update') && this.layoutHint.includes('sheet') && !!action.href) { 
      if (this.activeColumns) {
        const path = action.href.split('?')[0];
        const search = action.href.split('?')[1];
        const urlParams = new URLSearchParams(search);
        urlParams.set('ColumnIDs', this.activeColumns.map(col => col.id).join(','));
        action.href = `${path}?${urlParams.toString()}`;
      }
    }
    if (action.name?.includes('update') && action.type !== 'general' && !this.layoutHint.includes('grid-table')) { 
      if (!!action?.href) this.updateActionHref = action.href;
      this.toggleEdit(row!);
    } else if ((action.name === 'goto-object' || action.name?.startsWith('GoToObject-') || action.name === 'goto-object-new') && !!action?.href && !!row) {
      // If href is in format {module}/{case-type}/{case-id}, redirect to route
      const href = action.href;
      if (event?.ctrlKey || action.name === 'goto-object-new') {
        const newTabUrl = (this.router['location']._locationStrategy._platformLocation._location.origin as string) + href;
        window.open(newTabUrl, '_blank');
      } else {
        this.breadcrumbTabService.add({ label: '-', url: action.href, originalUrl: action.href, type: '- ', children: [] });
        this.footerToolbarService.reset();
        this.closeLookup.emit();
        void this.router.navigate([href]);
      }
      // }
    } else if (action.name?.includes('download-document')) this.downloadFile(action);
    else {
      this.currentRow = row;
      const refreshParent = action.layouthint?.includes('refresh-parent') || action.type === 'delete';
      const redirect = action.layouthint?.includes('redirect');
      const removeYourselfMessage = action.layouthint?.find(hint => hint.includes('remove_yourself'));
      const removeYourself = removeYourselfMessage?.split(':')?.[1];
      const removeOthersMessage = action.layouthint?.find(hint => hint.includes('remove_others'));
      const removeOthers = removeOthersMessage?.split(':')?.[1];
      const removeMessage =  removeYourself || removeOthers || this.redirectMessage;
      const isGridTable = this.layoutHint.includes('grid-table');
      const selfHref = !!row ? row._links?.self?.href : '';
      if (action.type === 'delete' || action.name?.startsWith('delete-'))
        this.removeTabHref = row?.actions.find(a => a.name == 'goto-object')?.href;

      this.refreshPanels = !!action.layouthint?.includes('refresh-panels');

      void this.beinformedService.handleAction(action, redirect ? undefined : !refreshParent ? this.reload : undefined, redirect ? undefined : refreshParent ? this.refreshCurrentParent : this.reload, false, undefined, removeMessage, isGridTable, selfHref);
    }
  }

  private downloadFile(action: IAction) {
    this.subs.push(
      this.fileService.downloading.subscribe(downloading => {
        action.name = downloading ? 'spinner' : 'download-document';
      })
    );
    this.fileService.downloadFile(action.href);
  }

  public toggleChild(row: ICaseListRow) {
    if (!this.loadedChildren.includes(row)) {
      this.loadedChildren.push(row);
      if (!this.loadingChildren.includes(row)) this.loadingChildren.push(row);
    }
    if (this.expandedChildren.includes(row)) {
      const rowIndex = this.expandedChildren.indexOf(row);
      this.expandedChildren.splice(rowIndex, 1);
    } else this.expandedChildren.push(row);
  }

  public toggleEdit(row: ICaseListRow) {
    if (this.expandedEditChildren.includes(row)) {
      const rowIndex = this.expandedEditChildren.indexOf(row);
      this.expandedEditChildren.splice(rowIndex, 1);
    } else this.expandedEditChildren.push(row);
  }

  public toggleRichText(row: ICaseListRow, columnId: number | string) {
    const rowIndex = this.expandedRichTextChildren.findIndex(child => child.row === row);
    if (rowIndex !== -1) {
      this.expandedRichTextChildren.splice(rowIndex, 1);
    } else this.expandedRichTextChildren.push({ row, columnId });
  }

  public hasRichTextChild(row: ICaseListRow) {
    return !!this.expandedRichTextChildren.find(child => child.row == row);
  }

  public showRichText(row: ICaseListRow): string {
    const child = this.expandedRichTextChildren.find(_child => _child.row === row);
    if (!!child) return decodeRich(child.row[child.columnId]) || 'No text';
    else return '';
  }

  public removeLoadingChild(row: ICaseListRow) {
    if (this.loadingChildren.includes(row)) {
      const rowIndex = this.loadingChildren.indexOf(row);
      this.loadingChildren.splice(rowIndex, 1);
    }
  }

  private readonly onEditRow = (row: ICaseListRow) => {
    if (this.editRow && this.rowForm.touched && this.rowForm.dirty) {
      this.dialogService.open({
        type: 'alert',
        title: 'table.unsaved_changes',
        text: 'table.continue_message',
        confirmLabel: 'continue',
        cancelLabel: 'back',
        closable: true
      }).subscribe(res => {
        if (res) this.fetchRowForm(row);
        else return;
      });
    } else this.fetchRowForm(row);
  };

  public onConfirmEdit = (row: ICaseListRow) => {
    if (this.rowForm.touched && this.rowForm.valid) {
      const rowLink = row.actions.find(action => action.name.includes('update'));
      const formBody = this.createFormBody(this.rowForm);
      this.httpService.post(rowLink?.href || '', formBody).subscribe({
        next: () => {
          this.reload();
          this.showMessage('table.inline_edit_success', 'success');
        },
        error: () => this.showMessage('table.inline_edit_fail', 'error')
      });
    }
    this.editRow = null;
    this.rowForm = new FormGroup({});
  };

  public onCancelEdit = () => {
    if (this.rowForm.dirty && this.rowForm.touched) {
      return this.dialogService.open({
        type: 'alert',
        title: 'table.cancel_update',
        text: 'table.discard_changes',
        confirmLabel: 'discard',
        cancelLabel: 'back',
        closable: true
      }).subscribe(res => {
        if (res) {
          this.editRow = null;
          this.rowForm = new FormGroup({});
        } else return;
      });
    } else {
      this.editRow = null;
      this.rowForm = new FormGroup({});
    }
  };

  private fetchRowForm(row: ICaseListRow) {
    this.editables = [];
    this.rowForm = new FormGroup({});
    const rowLink = row.actions.find(action => action.name.includes('update'));
    this.httpService.post(`${rowLink?.href}?commit=false`, {})
      .pipe(
        catchError((err: IFormResponse) => {
          const conLink = err.error?.formresponse._links?.contributions.href || '';
          this.httpService.get(conLink).subscribe(() => {
            // todo: set validation if needed
          });
          const formRes = err.error?.formresponse?.missing?.anchors[0];
          this.formMeta = formRes || {} as IFormAnchor;
          this.formTokens = err.error?.formresponse?.tokens || [];
          this.editRow = row;
          for (const key in row) {
            const elementIndex = formRes?.elements?.findIndex(item => item.elementid === key) ?? -1;
            if (elementIndex >= 0 && formRes?.elements[elementIndex].elementid !== 'ParentID') {
              this.rowForm.addControl(key, new FormControl(row[key]));
              this.editables.push(key);
            }
          }
          return of(undefined);
        })
      ).subscribe();
  }

  private readonly createFormBody = (form: FormGroup) => {
    const objectName = this.formMeta['objectid'];
    const tokens = this.formTokens;
    const elements = this.formMeta['elements'] as Record<string, any>[];
    const formObject = {} as Record<string, any>;
    elements.forEach(el => {
      const elementId = el.elementid;
      if (!!form.value[elementId]) {
        const elementValue = form.value[elementId];
        if (elementValue instanceof Object) formObject[elementId] = Object.values(elementValue)[0];
        else formObject[elementId] = form.value[elementId];
      } else formObject[elementId] = el.suggestion || '';
    });
    return {
      tokens,
      objects: [
        { [objectName]: formObject }
      ]
    };
  };

  private readonly setColumns = (tableData: ITableDataMapped) => {
    if (this.columnsToggled) return;
    let rcs: IRootColumn[] = [];
    if (!this.dataSource.tableConfiguration?.settings?.columns) {
      rcs = !!this.widgetConfig?.rootColumns?.length ? [...this.widgetConfig.rootColumns.map(c => ({ ...c }))] : tableData.tableColumns.map((colData: any, i: number) => {
        const colId = Object.keys(colData)[0];
        const data = Object.values(colData)[0] as Record<string, any>;
        const active = !data['layouthint']?.includes('default-hide');
        const title = !!data['layouthint']?.includes('title');

        const hiddenColumn = !!data['layouthint']?.find((hint: string) => hint === 'column-hide') ? colId : null;
        const hiddenColumns = ['ParentID', 'IsPrivate', 'IsParent', 'Href', 'StateJSON', 'HasChildren', 'ObjectID'];
        const columnShowHint = data['layouthint']?.find((hint: string) => hint.includes('column-hide:'));
        if (!!columnShowHint) {
          if (this.interpretLayouthint(columnShowHint, tableData.tableData)) {
            hiddenColumns.push(colId);
          }
        }

        if (colId.toLowerCase().endsWith('_symbol'))
          hiddenColumns.push(colId);

        !!hiddenColumn && hiddenColumns.push(hiddenColumn);
        this.iconColumName = !!data['layouthint']?.includes('icon-column') ? colId : this.iconColumName;
        this.iconColumNameOptions = !!data['layouthint']?.includes('icon-column') ? tableData.tableColumns.find(column => column.hasOwnProperty(colId))?.[colId]?.options : this.iconColumNameOptions;
        return {
          id: colId,
          label: data['label'] || colId,
          order: i,
          active,
          title,
          layouthint: data['layouthint'] || [],
          hiddenColumns
        };
      }).filter(col => !col.hiddenColumns.includes(col.id) &&
        !col.id.toLowerCase().includes('description') &&
        !col.id.toLowerCase().includes('relatedrecordType') &&
        !col.layouthint?.includes('unit-type') &&
        !col.layouthint?.includes('icon-column'));
    }
    this.rootColumns = this.dataSource.tableConfiguration?.settings?.columns || rcs;
    const columns = this.dataSource.tableConfiguration?.settings?.columns || rcs;
    this.activeColumns = columns.filter(col => col.active);
    const hasRowActions = tableData.tableData.some((obj: Record<string, any>) => !!obj.actions?.length);
    this.columnsToDisplay = this.sortColumns(columns, hasRowActions) as string[];
  };

  private interpretLayouthint(hint: string, tableData: Record<string, any>[]): boolean {
    const [columnName, condition, conditionValueBlock] = hint.replace('column-hide: ', '')?.trim().split(' ');
    let columnValue = tableData[0]?.[columnName];
    columnValue = columnValue === null || columnValue === undefined ? 'null' : columnValue;
    return CON_FUNCS[condition](columnValue, conditionValueBlock);
  }

  private sortColumns(colArr: (IRootColumn | string)[], hasActionsCol: boolean) {
    const columns = [...colArr];
    if (hasActionsCol) {
      const actionsIndex = columns.indexOf('actions');
      if (actionsIndex >= 0) columns.splice(actionsIndex, 1);
    }
    const sorted = (columns as IRootColumn[]).sort((a, b) => a.order - b.order);
    const filtered = sorted.filter(col => col.active);
    const mapped = filtered.map(col => col.id);

    if (hasActionsCol && !this.tableSettings?.child) mapped.push('actions');
    if (hasActionsCol && this.tableSettings?.child && this.stickyColumns.includes('actions')) mapped.push('actions_sticky');
    return mapped;
  }

  public showMessage(msg: string, type: string) {
    this.snackbar.open({ type, text: msg, dismissLabel: 'dismiss', duration: 5000 });
  }

  public reload = (refresh?: boolean, _close?: boolean, _refresh?: boolean, action?: IAction) => {
    if (this.refreshPanels) {
      this.tableService.refreshPanels$.next(true);
      return;
    }
    const dataOptions = this.dataSource.getCurrentDataOptions();
    this.dataSource.getTableData(this.dataUrl, this.isModal, dataOptions, true).subscribe(() => {
      const highlightRow = !!sessionStorage.getItem('highlightEditedRow') ? JSON.parse(sessionStorage.getItem('highlightEditedRow')!) : null;
      if (!!highlightRow && highlightRow.url === this.dataUrl) {
        this.highlightRow = highlightRow;
        sessionStorage.removeItem('highlightEditedRow');
        setTimeout(() => this.highlightRow = undefined, 5000);
      }
    });
    if (!!action) {
      const reloadTableHint = action.layouthint?.find(hint => hint.includes('refresh-table:'));
      if (!!reloadTableHint) {
        const reloadTarget = reloadTableHint.split(':')[1];
        this.tableService.targetedTableReload$.next(reloadTarget);
      }
    }
    this.dataSource.resetPages = true;
    this.loaded.emit(true);
    if (!this.isAssessment) {
      if (refresh && this.router.url.includes('audit-execution')) this.taskgroupService.refreshPage.next(true);
    }

    if (!!this.removeTabHref)
      this.breadcrumbTabService.closeByHref(this.removeTabHref);
  };

  public hasRowActions() {
    return this.columnsToDisplay.includes('actions') || this.columnsToDisplay.includes('actions_sticky');
  }

  public initializeTable(url: string, dataOptions?: IDataOptions, refresh?: boolean) {
    const ignoreDataOptions = this.layoutHint.includes('grid-table');
    this.dataSource.getTableData(url, this.isModal, dataOptions, refresh, false, ignoreDataOptions).subscribe(data => {
      if (this.error && !!dataOptions?.filter) {
        dataOptions.filter = undefined;
        this.dataSource.activeFilters = [];
        this.erasedFilters = true;
        this.initializeTable(url, dataOptions, refresh);
      }
      this.tableLayouthint = data.tableLayouthint;
      this.setColumns(data);
      if (this.tableSettings?.child && !!this.tableSettings?.expandChildren) {
        const firstWithChild = this.data.find(row => row.HasChildren === undefined || row.HasChildren === true);
        if (!!firstWithChild) this.toggleChild(firstWithChild);
      }
      if (this.erasedFilters && !this.error && !!dataOptions) {
        this.erasedFilters = false;
        this.tableSettingsService.saveOptionsConfiguration(this.dataUrl, this.dataSource.tableConfiguration, dataOptions, []);
        this.saveConfiguration();
      }
      const highlightRow = !!sessionStorage.getItem('highlightEditedRow') ? JSON.parse(sessionStorage.getItem('highlightEditedRow')!) : null;
      if (!!highlightRow && highlightRow.url === this.dataUrl) {
        this.highlightRow = highlightRow;
        sessionStorage.removeItem('highlightEditedRow');
        setTimeout(() => this.highlightRow = undefined, 5000);
      }
    });
  }

  public mapCellVal(value: any[][] | TValOrArr<string>, truncVal = true, row?: ICaseListRow, column?: IRootColumn) {
    const unitType = this.getUnit(row!, column!);

    const symbol = this.getSymbol(row, column);

    if (column?.layouthint?.includes('chips')) {
      const strArr: string[] = [];
      row!['TagIDs'].forEach((tag: string[]) => strArr.push(tag[0]));
      return strArr.join(', ');
    }
    if (!Array.isArray(value)) {
      const fullVal = !!unitType ? this.toUnit(value, unitType) : value;
      const returnValue = typeof value === 'string' && truncVal ? truncate(fullVal.toString() ?? '-', this.maxValLength) : fullVal ?? '-';
      return !!symbol ? `${symbol} ${returnValue}` : returnValue;
    }
    const mapped = value.slice(0 ,3)
      .map(cellVal => {
        if (Array.isArray(cellVal)) return cellVal.map(cv => !!unitType ? this.toUnit(cv, unitType) : cv).join(' | ');
        return !!unitType ? this.toUnit(cellVal, unitType) : cellVal;
      }).join(', ');
    return truncVal ? truncate(mapped, this.maxValLength) : mapped;
  }

  private getSymbol(row?: ICaseListRow, column?: IRootColumn): string | null {
    if (!row || !column?.layouthint?.includes('has-symbol')) return null;
    const symbolColumnId = `${column.id}_Symbol`;
    return row[symbolColumnId];
  }

  public mapFullTextVal(value: any[][] | string[]) {
    return value;
  }

  private toUnit(value: number | string, unitType: string) {
    const locale = this.languageService.getAppLang();
    if (!value) {
      return '';
    }
    switch (unitType) {
      case 'Financial': return (value as number).toLocaleString(locale, { style: 'currency', currency: 'EUR' });
      case 'Numeric': return (value as number).toLocaleString(locale);
      case 'Time': return durationCompact(+value, 'hours', locale);
      default: return value.toString();
    }
  }

  private getUnit(row: ICaseListRow, column: IRootColumn) {
    if (!column?.layouthint?.includes('unit')) return '';
    const typeCol = this.tableColumnsObj.find(colObj => {
      const [col] = Object.values(colObj);
      return (col as Record<string, any>).layouthint?.includes('unit-type');
    }) || {};
    return row?.[Object.keys(typeCol)[0]];
  }

  public showTooltip(value: TValOrArr<string>) {
    return !(Array.isArray(value) && value.length > 2) && this.mapCellVal(value, false)?.length > this.maxValLength;
  }

  public hasOverflow(el: HTMLSpanElement): boolean {
    return el.scrollWidth > el.offsetWidth;
  }

  public isArray(value: any) {
    return Array.isArray(value);
  }

  private preSelect(data: any[]) {
    this.preSelected.forEach(item => {
      const row = data.find(({ _id }) => [item._id, item.value].includes(_id)) || item;
      if (!this.selection.selected.some(({ value, _id }) => [row._id, row.value].includes(value || _id))) this.selection.select(row);
    });
  }

  public getDesc(row: ICaseListRow) {
    const rowDesc = Object.keys(row).find(key => key.toLowerCase().includes('description'));
    return row[rowDesc!];
  }

  public getQuestions(row: ICaseListRow) {
    const rowQuestions = Object.keys(row).find(key => key.toLowerCase().includes('questions'));
    return row[rowQuestions!]?.split('|').join(', ');
  }

  public getRelatedRecordType(row: ICaseListRow) {
    if (!this.iconColumName) return;
    return row[this.iconColumName]?.map((iconName: string) => this.iconColumNameOptions.find(x => x.label === iconName)?.code || iconName);
  }

  public getIconLabel(icon: string): string {
    return this.iconColumNameOptions.find(x => x.code === icon)?.label || icon;
  }

  public clickAvailable(row: ICaseListRow, objectName: string): void {
    this.tabs.activeTabName = objectName.endsWith('s') ? objectName.toLowerCase() === 'process' ? `${objectName}es` : objectName : `${objectName}s`;
    this.onRowClicked(row);
  }

  private setWidgetConfig(widgetConfig: IWidgetConfig) {
    this.previousWidgetConfig = clone(widgetConfig);
    this.newWidgetConfig = clone(widgetConfig);
    if (!this.dataSource.tableConfiguration.settings?.filter)
      if (!!this.widgetConfig?.filters) this.dataSource.setFilters(this.widgetConfig.filters);
    if (!!this.widgetConfig?.sorting) this.dataSource.sortingMeta = { ...this.widgetConfig.sorting };
    if (!!this.widgetConfig?.stickyColumns) this.stickyColumns = [...this.widgetConfig.stickyColumns];
    if (!!this.widgetConfig?.pagesize && !!this.tableSettings) {
      this.dataSource.pagingMeta.pagesize = this.widgetConfig.pagesize;
      this.tableSettings.pagesize = this.widgetConfig.pagesize;
    }
    if (!!this.widgetConfig?.title) {
      this.tableTitle = this.widgetConfig.title;
      this.titleInput = this.widgetConfig.title;
    }
  }

  public changePageSize(event: number) {
    this.dataSource.changePageSize(event);
    this.pagesizeChanged.emit(event);
    this.selection.clear();
    if (!!this.widgetConfig) this.newWidgetConfig = {...this.newWidgetConfig, pagesize: event};
  }

  public onTitleEdit(event?: any) {
    if (event?.key === 'Enter') {
      event.preventDefault();
      this.titleEdit = false;
      event.target.blur();
    } else if (!event) {
      this.titleEdit = !this.titleEdit;
      if (!this.titleEdit) this.saveTitle();
    }
  }

  public saveTitle() {
    if (!this.titleEdit) {
      if (!!this.titleInput) this.tableTitle = this.titleInput;
      else this.titleInput = this.tableTitle = 'Untitled';
      this.newWidgetConfig = { ...this.newWidgetConfig, title: this.tableTitle };
    }
  }

  public getChildUrls(row: ICaseListRow) {
    return !row?._links ? null : Object.entries(row._links)?.filter(([key]) => key !== 'self')?.map(([_, link]) => link?.href);
  }

  private setDataUrl() {
    if (!!this.lookupTokenParam) {
      const prefix = this.dataUrl.includes('?') ? '&' : '?';
      const permParam = `${prefix}${this.lookupTokenParam}`;
      this.dataUrl += permParam;
    }
    if (this.tableSettings?.parentTable) {
      const prefix = this.dataUrl.includes('?') ? '&' : '?';
      const permParam = !!this.tableConfig.child ? `${prefix}ParentID=${this.parentID}` : `${prefix}IsParent=true`;
      this.dataUrl += permParam;
    }
  }

  public isChecked(row: ICaseListRow) {
    return this.selection.selected.some(({ value, _id }) => [value, _id].includes(row._id));
  }

  public isBlocked(row: ICaseListRow) {
    return row.actions?.filter(action => action.name === 'unblock').length > 0;
  }

  public typeOf(value: any) {
    return typeof value;
  }

  public openRichText(colLabel: string, val: string, evt: MouseEvent) {
    evt.stopPropagation();
    const decodedVal = decodeRich(val) || 'No text';
    this.dialogService.open({
      title: colLabel,
      text: decodedVal,
      confirmLabel: 'ok',
      cancelLabel: 'cancel',
      closable: true,
      isRichText: true
    }).subscribe();
  }

  public toggleRow(row: ICaseListRow) {
    if (!this.isChecked(row)) this.selection.select(row);
    else {
      const foundItem = this.selection.selected.find(({ value, _id }) => [value, _id].includes(row._id));
      if (!!foundItem) this.selection.deselect(foundItem);
    }
  }

  public refreshCurrentParent = () => {
    if (!!this.tableConfig.child) this.refreshParent.emit(true);
    else this.reload();
  };

  public isStateColumn(columnId: string): boolean {
    return columnId === 'CaseState' || columnId === 'State' || columnId.endsWith('_State');
  }

  public getStateColor(row: any, column: any): string {
    const colName = `${column.id}Value`;
    const state = row[colName];
    return this.stateCaseColors[state]?.color;
  }

  public filterFormClick(event: Event) {
    this.tableService.filterFormClicked.next(event);
  }

  public getIconName(icon: string): string {
    const iconColumNameOption = this.iconColumNameOptions?.find(option => option.label === icon);
    return !!iconColumNameOption ? iconColumNameOption.code.toLowerCase() : icon.toLowerCase();
  }

  public handleSaved(event: IFormLookupCreateSuccess, panel: MatExpansionPanel, panelType: string, row?: any) {
    this.loading = true;
    const isAuditExeStructure = this.router.url.includes('audit-execution') && this.router.url.endsWith('structure') && !this.router.url.includes('wizard');
    if (this.router.routerState.snapshot.url.includes('collab')) this.refreshParent.emit(true);
    if (panelType === 'edit') sessionStorage.setItem('highlightEditedRow', JSON.stringify({url: this.dataUrl, row: row!._id}));
    if (event.refresh && !isAuditExeStructure) {
      this.reload(true);
    } else if (this.isModal && !this.tableSettings?.check) {
      (event as any).name = event.result.redirect.split('/').slice(-1)[0];
      this.lookupCreateSaved.emit(event);
    } else {
      const dataOptions = this.dataSource.getCurrentDataOptions();
      this.dataSource.getTableData(this.dataUrl, this.isModal, dataOptions, true).subscribe({next: res => {
        this.setData(res);
        if (!this.isTableReload) {
          const highlightRow = !!sessionStorage.getItem('highlightEditedRow') ? JSON.parse(sessionStorage.getItem('highlightEditedRow')!) : null;
          if (!!highlightRow && highlightRow.url === this.dataUrl) {
            this.highlightRow = highlightRow;
            sessionStorage.removeItem('highlightEditedRow');
            setTimeout(() => this.highlightRow = undefined, 5000);
          }
        }
      }});
      const tmpRow = Object.values(event.formValues as any)?.[0] as any;
      if (!!tmpRow && !!event.result.redirect && tmpRow.DocumentName !== '') {
        const splittedUrl = event.result.redirect.split('/');
        const labelId = +event.label! || -1;
        (tmpRow as ICaseListRow)._id = +splittedUrl.splice(-1) || labelId;
        (tmpRow as ICaseListAction).name = event.result.redirect.split('/').slice(-1)[0];
        if (!isNaN((tmpRow as ICaseListRow)._id) && (tmpRow as ICaseListRow)._id !== -1) this.selection.select(tmpRow as ICaseListRow);
      }
      panel.close();
    }
    setTimeout(() => {
      this.loading = false; 
      this.loaded.emit(true);
    });
  }

  public getUpdateEndpoint(row: ICaseListRow): string | null {
    if (!!this.updateActionHref) return this.updateActionHref;
    const foundAction = row.actions?.find(action => action.name.includes('update'));
    if (foundAction) return foundAction.href;
    else return null;
  }

  // get isWizard(): boolean {

  //   return this.router.url.includes('-wizard');
  // }

  public onOpenWizard() {
    if (!!this.tableWizardAction || !!this.tableEmptyPostAction) {
      this.onClickAction((this.tableWizardAction || this.tableEmptyPostAction) as IAction);
    }
  }

  public scrollInFocus(element: HTMLDivElement, expanded: boolean) {
    if (this.isModal) {
      if (expanded) element.scrollTo({ top: element.scrollTop + 100, behavior: 'smooth' });
      else element.scrollTo({ top: element.scrollTop - 100, behavior: 'smooth' });
    }
  }

  get isAssessment(): boolean {
    return this.router.url.includes('-assessment') && !this.router.url.includes('compliance-assessment');
  }

  get showSaveFilters(): boolean {
    return this.tableSettingsService.hasUpdates(this.dataUrl);
  }

  public saveConfiguration() {
    this.tableSettingsService.saveTableOptions();
  }

  public hamburgerCloseActionMenu(trigger: MatMenuTrigger) {
    this.menuTrigger = trigger;
    this.timeout = setTimeout(() => {
      trigger.closeMenu();
    }, 100);
  }

  public menuOpenActionMenu() {
    clearTimeout(this.timeout);
  }

  public menuCloseActionMenu() {
    this.menuTrigger.closeMenu();
  }

  public parseColorValue(colorValue: string): {color: string, value: any} {
    const colorValueObject = JSON.parse(colorValue);
    return {color: colorValueObject.color, value: roundTo(colorValueObject.value as number, 2)};
  }

  public textColor(hexColorCode: string) {
    const rgbObject = hexToRgb(hexColorCode);
    if (!rgbObject) return 'dark';
    if ((rgbObject.r*0.299 + rgbObject.g*0.587 + rgbObject.b*0.114) > 150) // waarde 150 kan aangepast worden om gevoeligheid in te stellen (standaard 186)
      return 'dark';
    else return 'light';
  }
  
}
